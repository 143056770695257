<template>
  <div class="tutorial-register">
    <p
      v-for="title in pageTitle"
      :key="title"
      class="page-title"
      v-text="title"
    ></p>
    <div class="rows">
      <img v-src="require('./assets/01.png')" alt="" />
      <p class="title">最专业的娱乐品牌</p>
      <p>
        我们是一个主打百家乐游戏的网站，持有合法网上赌牌，玩家无需担心任何资金和账户问题。经营线上博弈多年，已经吸引超过万名玩家注册，可说是百家乐游戏的权威性指标。更不断引进评价好的游戏馆别，多样化的游戏种类，让玩家有最新鲜更多元化的选择。
      </p>
      <p class="title">全网最多百家乐直播台</p>
      <p>全网最多百家乐直播台，同时投注多家赌场，丰富玩家的游戏体验。</p>
      <p class="title">24小时在线客服</p>
      <p>
        24小时客服在线等候咨询，从存款、注册、转帐到游戏，处理任何顾客问题，享受最安心、方便及快速的使用环境。
      </p>
      <p class="title red">快速注册会员！开始游戏</p>
      <p>
        想要加入我们的行列开始游戏吗？注册前只需准备平常使用的手机(收验证码)就可以开始啰！
      </p>
      <p class="subtitle">
        1. 首先请扫描注册二维码或点击此<a
        href="http://yh3388.vip/#/register?token=11530f52d6024eeb9fe68415bfbd06b1"
      >官网连结</a
      >
      </p>
      <img v-src="require('./assets/02.png')" alt="" class="qrcode" />
      <p class="subtitle">
        2. 扫描后点击二维码会在浏览器打开（建议使用<span class="red"
      >Chrome</span
      >浏览器)
      </p>
      <img v-src="require('./assets/03.png')" alt="" class="snap" />
      <p class="subtitle">3. 进入登录页面，点击用户注册</p>
      <img v-src="require('./assets/04.png')" alt="" class="snap" />
      <p class="subtitle">4. 首次游戏需自行创帐号（英数任意及一组密码）</p>
      <p></p>
      <p>依照顺序输入</p>
      <p>
        ① 帐号 ②密码 ③再次输入密码确认 ④选择区域号码后，输入手机号码
        ⑤点击发送验证码后，输入收到的验证码
      </p>
      <p>最后点击注册</p>
      <img v-src="require('./assets/05.png')" alt="" class="snap" />
      <p class="subtitle">5. 注册完成后，进入首页</p>
      <img v-src="require('./assets/06.png')" alt="" class="snap" />
      <p class="subtitle">
        当你完成以上流程，就可以进入我们的游戏平台，开始游戏啰！
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tutorial-register',
  data() {
    return {
      title: ['全网最大百家乐平台', '注册教学']
    }
  },
  computed: {
    pageTitle() {
      return this.$store.state.isMobileLayout
        ? this.title
        : [this.title.join(' - ')]
    }
  }
}
</script>

<style lang="scss" scoped></style>
